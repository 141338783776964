<!-- Details Panel -->
<div class="details-panel" *ngIf="remoteAccess">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ remoteAccess.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon>
                </button>
                <zx-status-icon class="d-inline-block" [model]="remoteAccess" size="sm" class="me-1"></zx-status-icon>{{ remoteAccess.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- View Radio Button -->
            <div class="d-block float-end">
                <label for="viewOptions" class="sr-only">{{ "VIEW" | translate }}</label>
                <mat-button-toggle-group id="viewOptions" name="viewOption" aria-label="view option" [(ngModel)]="viewOption" (change)="viewChange()">
                    <mat-button-toggle value="accordions" title="{{ 'ACCORDION_LIST' | translate }}">
                        <fa-icon icon="th-list" size="sm" flip="horizontal"></fa-icon>
                        <span class="sr-only">{{ "PANELS" | translate }}</span>
                    </mat-button-toggle>
                    <mat-button-toggle value="tabs" title="{{ 'TABS' | translate }}">
                        <fa-icon icon="folder" size="sm"></fa-icon>
                        <span class="sr-only">{{ "TABS" | translate }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <!-- Mobile Action Buttons -->
            <div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary me-2 mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="md"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <zx-mute [model]="remoteAccess" [type]="'remote_access'" class="w-100" *ngIf="canEdit(remoteAccess)"></zx-mute>
                            <button
                                (click)="editRemoteAccess(remoteAccess.name)"
                                class="text-primary"
                                title="{{ 'EDIT' | translate }}"
                                mat-menu-item
                                *ngIf="canEdit(remoteAccess)"
                            >
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button
                                (click)="cloneRemoteAccess(remoteAccess.name)"
                                class="text-primary"
                                title="{{ 'CLONE' | translate }}"
                                [disabled]="
                                    !resourceTags?.length ||
                                    (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                                "
                                mat-menu-item
                            >
                                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "CLONE" | translate }}
                            </button>
                            <button
                                (click)="toggle()"
                                [ngClass]="remoteAccess.is_enabled ? 'text-warning' : 'text-success'"
                                title="{{ (remoteAccess.is_enabled ? 'DISABLE' : 'ENABLE') | translate }}"
                                mat-menu-item
                                *ngIf="remoteAccess.type !== 'videon' && canEdit(remoteAccess)"
                            >
                                <fa-icon icon="power-off" size="sm" [fixedWidth]="true" *ngIf="!remoteAccess.is_enabled"></fa-icon>
                                <fa-icon icon="ban" size="sm" [fixedWidth]="true" *ngIf="remoteAccess.is_enabled"></fa-icon>
                                {{ (remoteAccess.is_enabled ? "DISABLE" : "ENABLE") | translate }}
                            </button>
                            <button
                                (click)="deleteRemoteAccess()"
                                class="text-danger"
                                title="{{ 'DELETE' | translate }}"
                                mat-menu-item
                                *ngIf="canEdit(remoteAccess)"
                            >
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <!-- Action Buttons -->
            <div class="d-none d-md-block float-end">
                <zx-mute [model]="remoteAccess" [type]="'remote_access'" class="me-2 mb-2" *ngIf="canEdit(remoteAccess)"></zx-mute>
                <div class="btn-group me-2 mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="editRemoteAccess(remoteAccess.name)"
                        title="{{ 'EDIT' | translate }}"
                        *ngIf="canEdit(remoteAccess)"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button
                        *ngIf="remoteAccess.type === 'zixi'"
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="cloneRemoteAccess(remoteAccess.name)"
                        title="{{ 'CLONE' | translate }}"
                        [disabled]="
                            !resourceTags?.length ||
                            (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                        "
                    >
                        <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "CLONE" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn"
                        (click)="toggle()"
                        [ngClass]="remoteAccess.is_enabled ? 'btn-outline-warning' : 'btn-outline-success'"
                        title="{{ (remoteAccess.is_enabled ? 'DISABLE' : 'ENABLE') | translate }}"
                        *ngIf="remoteAccess.type !== 'videon' && canEdit(remoteAccess)"
                    >
                        <fa-icon icon="power-off" size="sm" *ngIf="!remoteAccess.is_enabled"></fa-icon>
                        <fa-icon icon="ban" size="sm" *ngIf="remoteAccess.is_enabled"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ (remoteAccess.is_enabled ? "DISABLE" : "ENABLE") | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        (click)="deleteRemoteAccess()"
                        title="{{ 'DELETE' | translate }}"
                        *ngIf="canEdit(remoteAccess)"
                    >
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions" *ngIf="view === 'accordions'">
        <!-- Alerts -->
        <div class="row">
            <div class="col">
                <ngb-alert [dismissible]="false" [type]="'info'" *ngIf="remoteAccess.active_flapping && remoteAccess.is_enabled">
                    <strong>{{ "INFO" | translate }}</strong> - {{ "FLAPPING_STATE" | translate }}
                    <zx-date-time-zone [dateTime]="remoteAccess.flapping" />
                </ngb-alert>
                <zx-active-states
                    [activeStates]="remoteAccess.activeStates"
                    [refreshFunction]="refreshRemoteAccess"
                    [canEdit]="canEdit(remoteAccess)"
                    [objectName]="remoteAccess.name"
                ></zx-active-states>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-xxxl-8">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="d-xxxl-none">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-remote-access-details [remoteAccess]="remoteAccess" [canEdit]="canEdit(remoteAccess)"></app-remote-access-details>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Events -->
                <ngb-accordion #eventsAccordion="ngbAccordion" activeIds="ngb-panel-events" class="no-padding">
                    <ngb-panel id="ngb-panel-events">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'EVENTS' | translate }}">EVENTS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-events [objects]="{ remote_access: remoteAccess }" [id]="remoteAccess.id" [autoRows]="false"></zx-events>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>

            <div class="col-12 col-xxxl-4">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="d-none d-xxxl-block">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-remote-access-details [remoteAccess]="remoteAccess" [canEdit]="canEdit(remoteAccess)"></app-remote-access-details>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Notes -->
                <ngb-accordion #notesAccordion="ngbAccordion" activeIds="ngb-panel-notes" *ngIf="remoteAccess">
                    <ngb-panel id="ngb-panel-notes">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'NOTES' | translate }}">NOTES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-advanced-notes
                                [model]="remoteAccess"
                                [type]="'remote_access'"
                                [id]="remoteAccess.id"
                                [canEdit]="canEdit(remoteAccess)"
                            ></zx-advanced-notes>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>

    <!-- Tab Layout -->
    <div class="details-content-tabs" *ngIf="view === 'tabs'">
        <button type="button" class="btn btn-link scroll-left-btn" (click)="scrollLeft()" title="{{ 'SCROLL_LEFT' | translate }}" *ngIf="tabsetHasScroll">
            <fa-icon icon="chevron-left" size="md"></fa-icon>
        </button>
        <button type="button" class="btn btn-link scroll-right-btn" (click)="scrollRight()" title="{{ 'SCROLL_RIGHT' | translate }}" *ngIf="tabsetHasScroll">
            <fa-icon icon="chevron-right" size="md"></fa-icon>
        </button>
        <!-- Tabs -->
        <div class="tabset">
            <ul
                ngbNav
                [destroyOnHide]="false"
                [(activeId)]="activeTab"
                #remoteAccessTabset="ngbNav"
                [ngClass]="{ hasScroll: tabsetHasScroll }"
                class="justify-content-start nav nav-tabs"
            >
                <li ngbNavItem="details-tab" id="details-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="align-justify" size="sm" title="{{ 'DETAILS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "DETAILS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <!-- Alerts -->
                            <div class="row">
                                <div class="col">
                                    <ngb-alert [dismissible]="false" [type]="'info'" *ngIf="remoteAccess.active_flapping && remoteAccess.is_enabled">
                                        <strong>{{ "INFO" | translate }}</strong> - {{ "FLAPPING_STATE" | translate }}
                                        <zx-date-time-zone [dateTime]="remoteAccess.flapping" />
                                    </ngb-alert>
                                    <zx-active-states
                                        [activeStates]="remoteAccess.activeStates"
                                        [refreshFunction]="refreshRemoteAccess"
                                        [objectName]="remoteAccess.name"
                                    ></zx-active-states>
                                </div>
                            </div>
                            <!-- Details -->
                            <div class="row">
                                <div class="col-12 col-xxl-4 mb-3">
                                    <h3 class="d-md-none" title="{{ 'DETAILS' | translate }}">{{ "DETAILS" | translate }}</h3>
                                    <app-remote-access-details
                                        [remoteAccess]="remoteAccess"
                                        [canEdit]="canEdit(remoteAccess)"
                                        [canEdit]="canEdit(remoteAccess)"
                                    ></app-remote-access-details>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="events-tab" id="events-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="exclamation-triangle" size="sm" title="{{ 'EVENTS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "EVENTS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="mat-table-tab-container">
                            <h3 class="d-md-none" title="{{ 'EVENTS' | translate }}">{{ "EVENTS" | translate }}</h3>
                            <zx-events
                                [objects]="{ remote_access: remoteAccess }"
                                [id]="remoteAccess.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab'"
                            ></zx-events>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="notes-tab" id="notes-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon [icon]="['far', 'sticky-note']" size="md" title="{{ 'NOTES' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "NOTES" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'NOTES' | translate }}">{{ "NOTES" | translate }}</h3>
                            <form>
                                <div class="form-group">
                                    <zx-advanced-notes
                                        [model]="remoteAccess"
                                        [type]="'remote_access'"
                                        [id]="remoteAccess.id"
                                        [canEdit]="canEdit(remoteAccess)"
                                    ></zx-advanced-notes>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="remoteAccessTabset"></div>
        </div>
    </div>
</div>
